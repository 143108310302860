import * as React from 'react';
import Helmet from 'react-helmet';
import Header from './header'
import Footer from './footer';
import {graphql, StaticQuery, Link} from 'gatsby'
import './layout.css'


type Props = {
  children: any,
  pageTitle?: any,
  noFooterMargin?: boolean,
  className?: string,
  showNavBar?: boolean
};

const Layout = ({ children, pageTitle, noFooterMargin, className, showNavBar }: Props) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        sanityCompanyProfile(_id: {eq: "blumen-elsdoerfer"}) {
          phoneNumber
        }
      }
    `}
    render={data => {
      return <>
        <Helmet
          title={"Blumen Elsdörfer"}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
          <link rel="stylesheet" href="https://use.typekit.net/unq3aox.css" />
        </Helmet>

        <Header
          pageTitle={pageTitle}
          showNavBar={showNavBar}
        />

        <div style={{flex: 1}} className={className}>
          {children}
        </div>

        <Footer noMargin={noFooterMargin} />
      </>    
    }} />
)

export default Layout