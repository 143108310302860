import React from 'react';
import styled from "@emotion/styled"
import ContentMargins from './ContentMargins';
import {graphql, StaticQuery, Link} from 'gatsby'



const StyledFooter = styled.div`
  background: #9B1030;
  padding: 5px;
  color: white;
  border-bottom: 1px solid whitesmoke;
  padding: 30px 0px;  
`;


const FooterBlock = styled.div`
  float: left;

  h3 {
    margin-top: 0px;
  }
  
  li {
    list-style-type: none;
    margin: 4px 0px;
    
    a {
      color: white;
      text-decoration: none;
      font-weight: normal;
    }
    a:hover {
      text-decoration: underline;      
    }
  }
`;


const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const Footer = (props) => (
  <StaticQuery
    query={graphql`
      query FooterProductPages {
         allSanityProductPage(sort: {fields: [title]}) {
          edges {
            node {
              slug {
                current
              },
              title
            }
          }
        },
        allSanityKeyPoint(sort: {fields: [title]}) {
          edges {
            node {
              id,
              slug {
                current
              },
              title
            }
          }
        },
      }
    `}
    render={data => {
      return <StyledFooter style={{marginTop: props.noMargin ? 'undefined' : '40px'}}>
        <ContentMargins>
          <FooterContent>
            <FooterBlock>
              Blumen Elsdörfer, seit 1947

              <div style={{fontSize: '0.9em', marginTop: '30px'}}>
                <div><strong>Inhaber:</strong> Elsdörfer u. Söhne GbR</div>
                <div><strong>Ust Id Nr:</strong> DE257106458<br /></div>
                <div><strong>Adresse:</strong> Ungererstraße 157, 80805 München</div>
              </div>
            </FooterBlock>

            <FooterBlock>
              <h3>Unsere Leistungen</h3>
              {data.allSanityProductPage.edges.map(({node}) => {
                if (!node.slug) {
                  return null;
                }
                return <li key={node.slug.current}>
                  <Link to={`/${node.slug.current}`}>❀&nbsp;&nbsp;{node.title}</Link>
                </li>
              })}
            </FooterBlock>

            <FooterBlock>
              <h3>Wer wir sind</h3>
              {data.allSanityKeyPoint.edges.map(({node}) => {
                if (!node.slug) {
                  return null;
                }
                return <li key={node.id}>
                  <Link to={`/${node.slug.current}`}>❀&nbsp;&nbsp;{node.title}</Link>
                </li>
              })}
            </FooterBlock>
          </FooterContent>
        </ContentMargins>
      </StyledFooter>;
    }}
  />
)




export default Footer
