import * as React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { CSSProperties } from 'react'


export const buttonStyle = css`
  --color: #FFD166;
  /* #26547C, #26547C */
  
  display: inline-block;
  border: 1px solid black;
  padding: .4em 1.2em;
  background: var(--color);
  
  border-radius: 3px;
  font-size: 15px;
  cursor: pointer;
  
  color: black !important;
  text-decoration: none;
  font-weight: normal;
  :hover {
    text-decoration: none;
  }
  
  .label {
      font-size: 1.4em;
      font-weight: bold;
  }
   
  display: inline-flex;
  flex-direction: column;
  justify-content: center;  
  
  transition: all .3s ease-in-out;
  &:hover {
    text-decoration: none;
    --color: #FFC107;
  }  
`;


export function Button(props: {
  children?: any,
  style?: CSSProperties,
  className?: string

  href?: string,
  to?: string,
  onClick?: any
}) {
  const defaultProps: any = {
    css: buttonStyle,
    className: props.className,
    style: props.style
  };

  if (props.onClick) {
    defaultProps.onClick = props.onClick;
  }

  if (props.href) {
    return <a {...defaultProps} href={props.href}>{props.children}</a>
  }
  if (props.to) {
    return <Link {...defaultProps} to={props.to}>{props.children}</Link>
  }
  return <div {...defaultProps}>
    {props.children}
  </div>
}