import {css} from '@emotion/core'
import * as React from 'react'
import { Link } from './Link'


type Sluggy = {
  slug: {current: string}
};

export function MenuSelector(props: {
  links: {
    text: string,
    keypointLink: Sluggy,
    productPageLink: Sluggy,
    externalLink: string
  }[]
}) {
  return <div css={css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: stretch;
    
    > a {
      flex: 1;
      flex-basis: 200px
      min-width: 200px;
      
      height: 200px;
      background-size: cover;
      background-position: center center;
      
      font-size: 35px;
      font-weight: bold;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-decoration: none;
      
      // text-shadow: white 0px 0px 10px;
      
      transition: opacity 0.5s ease-in-out;
      &:hover {
        opacity: 0.5;
      }
      
      .text {
        background-color: #9b1031;
        color: #fff; 
        display: inline;
        padding: 0.4rem;
        
        box-decoration-break: clone;       
      }

    }    
  `}>
    {
      props.links.map((link: any) => {
        const url = getUrl(link);
        return <Link to={url} style={{
          "backgroundImage": link.image ? `url(${link.image.asset.fluid.src})` : undefined
        } as any}>
          <span className={'text'}>{link.text}</span>
        </Link>
      })
    }
  </div>
}


export function getUrl(link: any) {
  const url = link.keypointLink
    ? `/${link.keypointLink.slug.current}`
    : link.productPageLink
      ? `/${link.productPageLink.slug.current}`
      : link.externalLink;
  return url;
}

//       --cover-url: url(https://cdn.sanity.io/images/dqhqc5ti/production/a2741ea4c8b89b478da7eb2586fe378f3a96b5dd-5456x3632.jpg?w=800&h=100&fit=crop);