import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';


export const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_SANITY_TOKEN,
  useCdn: false
};

export const client = sanityClient(sanityConfig);

const builder = imageUrlBuilder(client)


export function makeImageUrl(source) {
  return builder.image(source)
}

export const isBrowser = () => typeof window !== 'undefined';