/**
 * A fork of
 * https://raw.githubusercontent.com/sanity-io/gatsby-source-sanity/63806eee909f4f291768b486270d9190e1488820/src/images/getGatsbyImageProps.ts
 *
 * Which is what the `gatsby-source-sanity` is using internally to generate a data structure as expected by "gatsby-image"
 * for image hosted on the Sanity CDN.
 *
 * Our changes:
 *
 * - Enable us to pass fit=clip to be used for the the Sanity Image CDN.
 * - Enable us to use imgix service instead of the Sanity CDN (imgix
 *   supporting watermarks).
 */

import * as parseUrl from 'url-parse'

export const DEFAULT_FIXED_WIDTH = 400
export const DEFAULT_FLUID_MAX_WIDTH = 800
export type ImageNode = ImageAsset | ImageObject | ImageRef | string | null | undefined

enum ImageFormat {
  NO_CHANGE = '',
  WEBP = 'webp',
  JPG = 'jpg',
  PNG = 'png',
}

type GatsbyImageProps = {
  base64: string | null
  aspectRatio: number
  src: string
  srcSet: string | null

  // You can put those as a <source> with image/webp, and supporting
  // browsers will pick it up.
  srcWebp: string
  srcSetWebp: string | null
}

type GatsbyFixedImageProps = GatsbyImageProps & {
  width: number
  height: number
}

type GatsbyFluidImageProps = GatsbyImageProps & {
  sizes: string
}

type ImageDimensions = {
  width: number
  height: number
  aspectRatio: number
}

type ImageMetadata = {
  dimensions: ImageDimensions
  lqip?: string
}

// I believe this is a structure defined by sanity
type BasicImageStub = {
  name: string
  assetId: string
  extension: string
  metadata: ImageMetadata
}

type ImageAsset = {
  url: string
  assetId: string
  extension: string
  metadata: ImageMetadata
  _id: string
}

type ImageRef = {
  _ref: string
}

type ImageObject = {
  asset: ImageRef | ImageAsset
}

export type SanityCdnArgs = {
  kind: 'sanity',
  loc: SanityLocation
};
export type ImgIxCdnArgs = {
  kind: 'imgix',
  // Obviously, more could be added
  auto?: string,
  mark?: string,
  "mark-scale"?: number,
  "fp-x"?: number,
  "fp-y"?: number,
  "fp-z"?: number,
  "fp-debug"?: boolean,
  crop?: "focalpoint"
};
type ImageCdnArgs = SanityCdnArgs|ImgIxCdnArgs;


export type FluidArgs = {
  maxWidth?: number
  maxHeight?: number
  sizes?: string,
  fit?: string,
  toFormat?: string,
  cdnArgs: ImageCdnArgs,
}

export type FixedArgs = {
  width?: number
  height?: number,
  toFormat?: string
  cdnArgs: ImageCdnArgs,
}

type SanityLocation = {
  projectId: string
  dataset: string
}

const idPattern = /^image-[A-Za-z0-9]+-\d+x\d+-[a-z]+$/
const sizeMultipliersFixed = [1, 1.5, 2, 3]
const sizeMultipliersFluid = [0.25, 0.5, 1, 1.5, 2, 3]


const imgixBase = 'https://blumenelsdoerfer.imgix.net';

function makeSizingUrl(image: BasicImageStub, base: {
  width: number,
  height: number,
  fit: string,
  format?: string|null
}, args: ImageCdnArgs) {
  if (args && args.kind === 'imgix') {
    let query = {
      w: "" + base.width,
      h: "" + base.height,
      fit: base.fit,
      auto: args.auto,
      mark: args.mark,
      "mark-scale": args['mark-scale'],
      "fp-x": args['fp-x'],
      "fp-y": args['fp-y'],
      "fp-z": args['fp-z'],
      "fp-debug": args['fp-debug'],
      crop: args.crop
    };
    Object.keys(query).forEach(key => !query[key] && delete query[key]);  // must delete undefined or empty
    const params = new URLSearchParams(query as any);

    return `${imgixBase}/${image.name}?${params.toString()}`;
  }

  else if (args.kind === 'sanity') {
    const url = buildSanityImageUrl(args.loc, image);
    let generatedUrl = `${url}?w=${base.width}&h=${base.height}&fit=${base.fit}`;
    if (base.format) {
      return convertSanityUrlToFormat(generatedUrl, base.format);
    }
    else {
      return generatedUrl;
    }
  }

  else {
    throw new Error("unknown kind")
  }
}

function buildSanityImageUrl(loc: SanityLocation, stub: BasicImageStub) {
  const {projectId, dataset} = loc
  const {name} = stub
  const base = 'https://cdn.sanity.io/images'

  return `${base}/${projectId}/${dataset}/${name}`
}

function getBasicImageProps(node: ImageNode): BasicImageStub | false {
  if (!node) {
    return false
  }

  const obj = node as ImageObject
  const ref = node as ImageRef
  const img = node as ImageAsset

  let id: string = ''
  if (typeof node === 'string') {
    id = node
  } else if (obj.asset) {
    id = (obj.asset as ImageRef)._ref || (obj.asset as ImageAsset)._id
  } else {
    id = ref._ref || img._id
  }

  const hasId = !id || idPattern.test(id)
  if (!hasId) {
    return false
  }

  const [, assetId, dimensions, extension] = id.split('-')
  const [width, height] = dimensions.split('x').map(num => parseInt(num, 10))
  const aspectRatio = width / height
  const metadata = img.metadata || {dimensions: {width, height, aspectRatio}}

  return {
    name: `${assetId}-${width}x${height}.${extension}`,
    assetId,
    extension,
    metadata,
  }
}

function convertSanityUrlToFormat(url: string, toFormat: string) {
  const parsed = parseUrl(url, true)
  const filename = parsed.pathname.replace(/.*\//, '')
  const extension = filename.replace(/.*\./, '')
  const isConvertedToTarget = parsed.query.fm === toFormat
  const isOriginal = extension === toFormat

  // If the original matches the target format, remove any explicit conversions
  if (isConvertedToTarget && isOriginal) {
    const {fm, ...params} = parsed.query
    parsed.set('query', params)
    return parsed.toString()
  }

  if (isConvertedToTarget || isOriginal) {
    return url
  }

  const newQuery = {...parsed.query, fm: toFormat}
  parsed.set('query', newQuery)
  return parsed.toString()
}

function isWebP(stub: BasicImageStub) {
  return stub.extension === 'webp';
}

/**
 * Generates data for gatsby-image in fixed mode, which is an srcset
 * with 1x, 2x etc. modifiers.
 */
export function getFixedGatsbyImage(
  image: ImageNode,
  args: FixedArgs,
): GatsbyFixedImageProps | null {
  const stub = getBasicImageProps(image)
  if (!stub) {
    return null
  }

  let width = args.width;
  const height = args.height

  const {metadata, extension} = stub
  const {dimensions, lqip} = metadata
  let desiredAspectRatio = dimensions.aspectRatio

  // We base our calculations off the width, so we need to make sure we have one.
  if (!width) {
    if (height) {
      width = height * dimensions.aspectRatio;
    }
    else {
      width = dimensions.width;
    }
  }

  // If we're cropping, calculate the specified aspect ratio
  let fit = 'clip';
  if (args.height && args.width) {
    desiredAspectRatio = args.width / args.height
    fit = 'crop';
  }

  let forceConvert: string | null = null
  if (args.toFormat) {
    forceConvert = args.toFormat
  } else if (isWebP(stub)) {
    forceConvert = 'jpg'
  }

  const widths = sizeMultipliersFixed.map(scale => Math.round(width * scale))
  const initial = {webp: [] as string[], base: [] as string[]}
  const srcSets = widths
    .filter(currentWidth => currentWidth <= dimensions.width)
    .reduce((acc, currentWidth, i) => {
      const resolution = `${sizeMultipliersFixed[i]}x`
      const currentHeight = Math.round(currentWidth / desiredAspectRatio)
      const baseUrl = makeSizingUrl(stub,
        {width: currentWidth, height: currentHeight, fit: fit, format: forceConvert || stub.extension}, args.cdnArgs);
      const webpUrl = makeSizingUrl(stub,
        {width: currentWidth, height: currentHeight, fit: fit, format: 'webp'}, args.cdnArgs);
      acc.webp.push(`${webpUrl} ${resolution}`)
      acc.base.push(`${baseUrl} ${resolution}`)
      return acc
    }, initial)

  const outputHeight = Math.round(height ? height : width / desiredAspectRatio)
  const imgUrl = makeSizingUrl(stub, {width, height: outputHeight, fit: fit}, args.cdnArgs);

  return {
    base64: lqip || null,
    aspectRatio: desiredAspectRatio,
    width: Math.round(width),
    height: outputHeight,
    src: convertSanityUrlToFormat(imgUrl, forceConvert || extension),
    srcWebp: convertSanityUrlToFormat(imgUrl, 'webp'),
    srcSet: srcSets.base.join(',\n') || null,
    srcSetWebp: srcSets.webp.join(',\n') || null,
  }
}

/**
 * Generates data for gatsby-image in fluid mode, which is an srcset
 * with a number of sizes until reaching the specified max.
 */
export function getFluidGatsbyImage(
  image: ImageNode,
  args: FluidArgs
): GatsbyFluidImageProps | null {
  const stub = getBasicImageProps(image)
  if (!stub) {
    return null
  }

  const { metadata, extension} = stub
  const {dimensions, lqip} = metadata

  const fitMode = args.fit || 'crop';
  const maxWidth = args.maxWidth || DEFAULT_FLUID_MAX_WIDTH
  let desiredAspectRatio = dimensions.aspectRatio

  // If we're cropping, calculate the specified aspect ratio
  if (args.maxHeight) {
    desiredAspectRatio = maxWidth / args.maxHeight
  }

  const maxHeight = args.maxHeight || Math.round(maxWidth / dimensions.aspectRatio)

  let forceConvert: string | null = null
  if (args.toFormat) {
    forceConvert = args.toFormat
  } else if (isWebP(stub)) {
    forceConvert = 'jpg'
  }

  const sizes = args.sizes || `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`
  const widths = sizeMultipliersFluid
    .map(scale => Math.round(maxWidth * scale))
    .filter(width => width < dimensions.width)
    .concat(dimensions.width)

  const initial = {webp: [] as string[], base: [] as string[]}
  const srcSets = widths
    .filter(currentWidth => currentWidth <= dimensions.width)
    .reduce((acc, currentWidth) => {
      const currentHeight = Math.round(currentWidth / desiredAspectRatio)

      const baseUrl = makeSizingUrl(stub,
        {width: currentWidth, height: currentHeight, fit: fitMode, format: forceConvert || stub.extension}, args.cdnArgs);
      const webpUrl = makeSizingUrl(stub,
        {width: currentWidth, height: currentHeight, fit: fitMode, format: 'webp'}, args.cdnArgs);

      acc.webp.push(`${webpUrl} ${currentWidth}w`)
      acc.base.push(`${baseUrl} ${currentWidth}w`)
      return acc
    }, initial)

  const src = makeSizingUrl(
    stub,
    {width: maxWidth, height: maxHeight, fit: fitMode, format: forceConvert || extension},
    args.cdnArgs
  );
  const srcWebp = makeSizingUrl(
    stub,
    {width: maxWidth, height: maxHeight, fit: fitMode, format: 'webp'},
    args.cdnArgs
  );

  return {
    base64: lqip || null,
    aspectRatio: desiredAspectRatio,
    src,
    srcWebp,
    srcSet: srcSets.base.join(',\n') || null,
    srcSetWebp: srcSets.webp.join(',\n') || null,
    sizes,
  }
}