import * as React from 'react';
import styled from "@emotion/styled"
import {css} from "@emotion/core"
import Helmet from 'react-helmet';
import ContentMargins from './ContentMargins';
import { graphql, StaticQuery, useStaticQuery } from 'gatsby'
import { Button } from './Button'
import { getCurrentState } from 'openinghours.js'
import { NavBar } from './NavBar'
import { isBrowser, sanityConfig } from '../utils'
import { Link } from './Link'
import { getFixedGatsbyImage, SanityCdnArgs } from './getGatsbyImageProps'
import {DateTime} from 'luxon';


const FixedHeader = styled.header`
  position: fixed; 
  width: 100%; 
  top: 0; 
  background-color: whitesmoke;
  z-index: 15;
`;


const TopMostLogoBar = styled.div`
  padding: 5px;
  text-align: center;

  img {
    height: 50px; 
  }
  
  .pageTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    line-height: 0.5;
  }
  
  @media screen and (max-width: 700px) {
    .pageTitle {
      display: none;
    }
  }  
`;


const openingHours: any = [
  {
    dayOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    opens: '08:30',
    closes: '17:00'
  },
  {
    dayOfWeek: ['saturday'],
    opens: '08:30',
    closes: '15:00'
  },
  // {
  //   dayOfWeek: ['sunday'],
  //   opens: '10:00',
  //   closes: '12:00'
  // },

  {
    validFrom: '2019-12-25',
    validThrough: '2020-01-07',
    opens: '00:00',
    closes: '00:00'
  }
]


type Props = {
  pageTitle?: string,
  showNavBar?: boolean,
  customRightContent?: any
}

const Header = ({ pageTitle, showNavBar, customRightContent }: Props) => (
<StaticQuery
  query={graphql`
    query HeaderQuery {
      info: sanityCompanyProfile(_id: {eq: "blumen-elsdoerfer"}) {
        phoneNumber,
        logo {
          asset {
            _id,
            url,
            assetId,
            extension,
            metadata {
              dimensions {
                width,
                height,
                aspectRatio
              },
              lqip
            }
          }
        }
      }
    }
  `}
  render={data => {
    const img = getFixedGatsbyImage(
      data.info.logo,
      {height: 40, cdnArgs: {kind: 'sanity', loc: sanityConfig} as SanityCdnArgs});

    return <FixedHeader>
      <TopMostLogoBar>
          <ContentMargins style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            position: 'relative',
            height: '56px',
            boxSizing: 'border-box'
          }}>
            <div className={"pageTitle"}>
              <Link to={"/"}>
                <img srcSet={img.srcSet} alt={"Logo Blumen Elsdörfer"} />
              </Link>
              <div style={{
                fontSize: 29,
                paddingLeft: 20
              }}>
                {pageTitle}
              </div>
            </div>

            <div style={{flex: 1, marginLeft: "20px"}}>
              {customRightContent ? customRightContent : <Contact phoneNumber={data.info.phoneNumber} />}
            </div>
          </ContentMargins>
      </TopMostLogoBar>
      {showNavBar ? <NavBar /> : null}
      <Helmet
        htmlAttributes={{
          class: !showNavBar ? 'noHeader' : null
        }}
      />
    </FixedHeader> 
  }} />
)

Header.defaultProps = {
  showNavBar: true
}

export default Header


const contact = css`  
  text-align: right;
  min-width: 350px;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  
  .closed {
    color: #f44336;
  }
  
  .open {    
    color: green;
  }
  
  .closing {
    font-weight: bold;
    color: #2196F3;
  }
  
  .closed {
    color: #797979;
  }
  
  .phone {
    font-weight: bold;
  }
  
  a {
    font-weight: normal;
  }
  
  strong {
    text-decoration: none;
  }
`;

function Contact(props) {
  const now = DateTime.local();
  //const now = DateTime.fromJSDate(new Date(2019, 11, 23, 19, 10, 0), {zone: 'Europe/Berlin'});

  const state = getCurrentState(openingHours,
    {
      date: now,
      rulesTimezone: 'Europe/Berlin'
    }
  );

  return <div css={contact}>
    <div style={{flex: 1}}>
      <div>
        <span className={"phone"}>{props.phoneNumber}</span> &bull; <span><Link to={"/anfahrt"}>Anfahrt</Link></span>
      </div>
      <div>
        {isBrowser() ? <><Link to={"/öffnungszeiten"}>{formatOpeningHoursState(state, now)}</Link> &bull;</> : null} <span><Link to={"/öffnungszeiten"}>Öffnungszeiten</Link></span>
      </div>
    </div>
    <div>
      <Button style={{marginLeft: '15px'}} to={"/kontakt"}>Kontakt</Button>
    </div>
  </div>
}


function formatOpeningHoursState(state, now: DateTime) {
  const nextDate = DateTime.fromJSDate(state.isOpen ? state.closesAt : state.opensAt, {zone: 'Europe/Berlin'});
  const diff = nextDate.diff(now);

  let format;
  if (diff.as('days') > 7) {
    format = 'ccc, d. MMM, HH:mm';
  }
  else if (diff.as('days') > 1) {
    format = 'ccc, HH:mm';
  }
  else if (now.day != nextDate.day) {
    format = 'ccc, HH:mm';
  }
  else {
    format = "HH:mm";
  }

  if (state.isOpen) {
    if (diff.as('hours') < 1) {
      return <span className={"closing"}>Laden schliesst bald</span>
    }

    return <span className={"open"}>
      <strong>Laden geöffnet</strong> - bis {nextDate.toFormat(format)} Uhr
    </span>
  }
  else {
    return <span className={"closed"}>
      <strong>Laden geschlossen</strong> - öffnet {nextDate.toFormat(format, {locale:  'de'})} Uhr
    </span>
  }
}